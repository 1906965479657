/* @import '~antd/dist/antd.css'; */

/*
* demo.css
* File include item loop only specific css only
******************************************************************************/
.MuiTablePagination-toolbar{
  justify-content: center;
  display: flex;
  align-items: baseline;
}

.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 18px;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  padding: 7px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border 0.3s, box-shadow 0.3s;
}

.offcanvas-body{
  height: 100vh;
}

.content-wrapper .navbar {
  z-index: auto;
  border-radius: 8px;
}

.pvtAxisContainer, .pvtVals {
  border: 1px solid #a2b1c6;
  background: #f2f5fa;
  /* padding: 5px; */
  min-width: 20px;
  min-height: 20px;
}

table.pvtTable thead tr th, table.pvtTable tbody tr th {
  background-color: #ebf0f8;
  border: 1px solid #c8d4e3;
  font-size: 8pt;
  padding: 5px;
}

.pvtRenderers {
  border: 1px solid #a2b1c6;
}


.MuiTableCell-head .MUIDataTableHeadCell{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  /* padding: 16px; */
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
}

.tss-1akey0g-MUIDataTableHeadCell-data {
  display: inline-block;
  margin-left: -16px;
}